





























import { Prop, Vue, Component } from "vue-property-decorator"
import { preventNonNumericKeys } from "~/utils/validate"

export type BoundedItem = {
  title: string
  min: string | undefined
  max: string | undefined
  errorMessage?: string
  null: boolean
  options?: {
    filter?: string
    unit?: string
  }
}

@Component
export default class BoundedInputCard extends Vue {
  @Prop({ required: true })
  boundedItem!: BoundedItem
  error = ""

  mounted() {
    this.checkInput()
  }

  get formatCurrencyMin() {
    if (this.boundedItem.min === "") return ""
    if (this.boundedItem.min)
      return new Intl.NumberFormat("FR-fr", { style: "decimal" }).format(
        parseInt(this.boundedItem.min, 10)
      )
    return ""
  }

  set formatCurrencyMin(value) {
    this.boundedItem.min = value.replace(/\s+|[a-z]+/g, "")
  }

  get formatCurrencyMax() {
    if (this.boundedItem.max === "") return ""
    if (this.boundedItem.max)
      return new Intl.NumberFormat("FR-fr", { style: "decimal" }).format(
        parseInt(this.boundedItem.max, 10)
      )
    return ""
  }

  set formatCurrencyMax(value) {
    this.boundedItem.max = value.replace(/\s+|[a-z]+/g, "")
  }

  get subText(): string {
    let filter: undefined | string = this.boundedItem?.options?.filter
    let min: string | undefined, max: string | undefined
    if (filter === "numberToK") {
      ;[min, max] = [
        this.numberToK(this.boundedItem.min),
        this.numberToK(this.boundedItem.max),
      ]
    } else {
      ;[min, max] = [
        this.boundedItem.min?.toString(),
        this.boundedItem.max?.toString(),
      ]
    }
    if (!this.boundedItem.max && this.boundedItem.min)
      return `à partir de ${min}${this.boundedItem?.options?.unit}`
    else if (!this.boundedItem.max && !this.boundedItem.min) return ""
    else if (this.boundedItem.max && !this.boundedItem.min)
      return `jusqu'à ${max}${this.boundedItem?.options?.unit}`
    else if (this.boundedItem.max === this.boundedItem.min)
      return `exactement ${max}${this.boundedItem?.options?.unit}`
    else return `de ${min} à ${max}${this.boundedItem?.options?.unit}`
  }

  numberToK(value: string | undefined): string {
    if (!value) value = ""
    let valueParsed = parseInt(value, 10)
    if (!valueParsed) return ""
    if (valueParsed >= 1_000_000) {
      return Math.round(valueParsed / 1_000_000).toString() + "M"
    } else if (valueParsed >= 10_000) {
      return Math.round(valueParsed / 1000).toString() + "K"
    }
    return value.toString()
  }

  onKeydown(event: KeyboardEvent) {
    preventNonNumericKeys(event)
  }

  checkInput() {
    this.error = ""
    let { min, max } = this.boundedItem
    if (!min || !max) return
    let minValue = +min.replace(/\s+/g, "")
    let maxValue = +max.replace(/\s+/g, "")
    if (minValue > maxValue) {
      return (this.error = "Minimum supérieur au maximum")
    }
  }
}
