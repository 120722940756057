











import { Vue, Component, Prop } from "vue-property-decorator"

export type HousingType = "all" | "house" | "flat"

@Component({
  components: {},
})
export default class SearchHouseTypeInputs extends Vue {
  @Prop({ required: true })
  value!: HousingType

  handleInput(value: HousingType) {
    this.$emit("input", value)
  }
}
