









import { Vue, Component, Prop } from "vue-property-decorator"
import BoundedInputCard, {
  BoundedItem,
} from "@/components/search/BoundedInputCard.vue"
import SearchHouseType, {
  HousingType,
} from "@/components/search/SearchHouseType.vue"

export type BoundedItems = {
  price: BoundedItem
  living_area: BoundedItem
  pricem2: BoundedItem
  room_count: BoundedItem
  bedroom_count: BoundedItem
  estate_area: BoundedItem
}

@Component({
  components: {
    BoundedInputCard,
    SearchHouseType,
  },
})
export default class BoundedInputs extends Vue {
  @Prop({ required: true }) items!: BoundedItems
  @Prop({ default: "all" }) housingType!: HousingType

  onChangeHousingType(value: HousingType) {
    this.$emit("change-housing-type", value)
  }
}
